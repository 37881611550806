import React from "react";
import Sponsoren from "./Sponsoren";

const Sponsorenpage = () => {
  return (
    <div className="container-fluid w-lg-75">
      <Sponsoren />
    </div>
  );
};

export default Sponsorenpage;
